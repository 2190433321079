import React, { useState, useCallback } from 'react';
import { type LocalFile, type ParseStepResult, parse } from 'papaparse';

import { useNotificationContext } from '../context/Notification';
import {
    type MailingListImport,
    FileType,
    ProcessStatus as MailingListImportProcessStatus,
    useService as useMailingListImportService,
} from '../services/MailingListImports';
import {
    type MailingList,
    ProcessStatus as MailingListProcessStatus,
    useService as useMailingListService,
} from '../services/MailingLists';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LinearProgress from '@mui/material/LinearProgress';

import FileUpload from './FileUpload';
import RowErrors from './RowErrors';

export interface Row {
    Description?: string;
    'First Name'?: string;
    'Last Name'?: string;
    Email?: string;
    'Phone Number'?: string;
    'Company Name'?: string;
    'Job Title'?: string;
    Address?: string;
    'Address 2'?: string;
    City?: string;
    'Province or State'?: string;
    'Postal or Zip'?: string;
    'Country Code'?: string;
}

export const ADDRESS_MAPPING = {
    description: 'Description',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    companyName: 'Company Name',
    jobTitle: 'Job Title',
    addressLine1: 'Address',
    addressLine2: 'Address 2',
    city: 'City',
    provinceOrState: 'Province or State',
    postalOrZip: 'Postal or Zip',
    countryCode: 'Country Code',
} as const;

export const ADDITIONAL_ADDRESS_MAPPING = {
    amount: 'Amount',
    memo: 'Memo',
    chequeNumber: 'Cheque Number',
} as const;

export type PGField =
    | keyof typeof ADDRESS_MAPPING
    | keyof typeof ADDITIONAL_ADDRESS_MAPPING;

const normalizeKey = (key: string) => key.toLowerCase().replace(/\s+/g, '');

const isValidRow = (r: Row) => {
    // Find keys for which there are valid non-empty values
    const normKeys = Object.entries(r)
        .filter(([_, v]) => typeof v === 'string' && v.trim().length !== 0)
        .map(([k, _]) => normalizeKey(k));

    return (
        (normKeys.includes('firstname') || normKeys.includes('companyname')) &&
        normKeys.includes('addressline1') &&
        normKeys.includes('countrycode')
    );
};

export interface MappedHeader {
    /** The original user header */
    csvHeader: string;
    /** A PG specific field we expect */
    pgField: PGField | null;
}

export interface HeaderOptions {
    enableChequeHeaders?: boolean;
}

const matchHeaderToPGField = (
    header: string,
    options?: HeaderOptions
): PGField | null => {
    const normalizedHeader = normalizeKey(header);

    interface FieldWithCandidates {
        candidates: string[];
        field: PGField;
        /**
         * `exact` means the candidate must match exactly to the header.
         *
         * `normalized` (default if not provided) means the candidate has been
         * normalized and can map to the normalized header.
         */
        matchType?: 'exact' | 'normalized';
    }

    const fieldsWithCandidates: FieldWithCandidates[] = [
        // Order is important as `address` is in both `address2` and `address`
        { candidates: ['address2'], field: 'addressLine2' },
        { candidates: ['address'], field: 'addressLine1' },
        { candidates: ['city'], field: 'city' },
        { candidates: ['province', 'state'], field: 'provinceOrState' },
        { candidates: ['postal', 'zip'], field: 'postalOrZip' },
        { candidates: ['country'], field: 'countryCode' },
        { candidates: ['firstname'], field: 'firstName' },
        { candidates: ['lastname'], field: 'lastName' },
        // Order here is important again, if we don't match on either `firstname`,
        // `lastname` or `companyname`, we default to `name`
        { candidates: ['name', 'companyname'], field: 'companyName' },
        { candidates: ['description'], field: 'description' },
        { candidates: ['email'], field: 'email' },
        { candidates: ['phonenumber'], field: 'phoneNumber' },
        { candidates: ['jobtitle'], field: 'jobTitle' },
    ];

    if (options?.enableChequeHeaders) {
        fieldsWithCandidates.push(
            ...([
                { candidates: ['chequenumber'], field: 'chequeNumber' },
                { candidates: ['memo'], field: 'memo' },
                { candidates: ['Amount'], field: 'amount', matchType: 'exact' },
            ] as FieldWithCandidates[])
        );
    }

    for (const { candidates, field, matchType } of fieldsWithCandidates) {
        if (matchType === 'exact') {
            if (candidates.some((c) => header === c)) {
                return field;
            }
        } else if (candidates.some((c) => normalizedHeader.includes(c))) {
            return field;
        }
    }

    return null;
};

export const mapUserHeaders = (
    userHeaders: string[],
    headerOptions?: HeaderOptions
): MappedHeader[] => {
    const mappedHeaders: MappedHeader[] = [];

    for (const header of userHeaders) {
        const match = matchHeaderToPGField(header, headerOptions);

        if (!match) {
            mappedHeaders.push({ csvHeader: header, pgField: null });
            continue;
        }

        // If we already mapped the field, do not map it any more times
        if (mappedHeaders.some((h) => h.pgField === match)) {
            mappedHeaders.push({ csvHeader: header, pgField: null });
        } else {
            mappedHeaders.push({ csvHeader: header, pgField: match });
        }
    }

    return mappedHeaders;
};

interface CSVData {
    validRowCount: number;
    invalidRowCount: number;
    errors: string[];
    headers: MappedHeader[];
}

export const parseCSV = (
    file: File,
    headerOptions?: HeaderOptions
): Promise<CSVData> => {
    return new Promise((resolve) => {
        let headers: MappedHeader[] | null = null;
        const errors: string[] = [];
        let invalidRowCount = 0;
        let validRowCount = 0;

        parse(file as LocalFile, {
            // Allows many different delimeters, restrict to only commas as
            // our backend currently only supports this
            delimiter: ',',
            skipEmptyLines: true,
            step: (row: ParseStepResult<string[]>) => {
                if (headers === null) {
                    if (new Set(row.data).size !== row.data.length) {
                        errors.push(
                            'Duplicate CSV columns detected. Please remove any duplicate headers'
                        );
                    }

                    headers = mapUserHeaders(row.data, headerOptions);
                    return;
                }

                // Create a `row` object from the headers
                const _row: Record<string, string> = {};
                for (const [i, key] of headers.entries()) {
                    // Use the PG field first if we can, fallback to the
                    // user provided header
                    _row[key.pgField ?? key.csvHeader] = row.data[i];
                }

                if (isValidRow(_row)) {
                    ++validRowCount;
                } else {
                    ++invalidRowCount;
                }
            },
            complete: () => {
                resolve({
                    validRowCount,
                    invalidRowCount,
                    errors,
                    headers: headers ?? [],
                });
            },
        });
    });
};

export interface CompletedUploadData {
    mailingList: MailingList;
    mailingListImport: MailingListImport;
}

interface UploadContactsDialogProps {
    open: boolean;
    onClose: (e: {}) => void;
    onCompleted: (d: CompletedUploadData) => void;
    sampleURL?: string;
    csvHeaderOptions?: HeaderOptions;
}

export const generateReceiverMappings = (
    userHeaders: MappedHeader[]
): {
    receiverAddressMapping: Record<string, string>;
    receiverMergeVariableMapping: Record<string, string>;
} => {
    const receiverAddressMapping: Record<string, string> = {};
    const receiverMergeVariableMapping: Record<string, string> = {};
    // We want these to be mapped specifically to the merge variables and not
    // on the address mapping.
    const mergeFields: PGField[] = ['amount', 'memo', 'chequeNumber'];

    for (const { csvHeader, pgField } of userHeaders) {
        if (!pgField) {
            receiverMergeVariableMapping[csvHeader] = csvHeader;
            continue;
        }

        if (mergeFields.includes(pgField)) {
            receiverMergeVariableMapping[pgField] = csvHeader;
        } else {
            receiverAddressMapping[pgField] = csvHeader;
        }
    }

    return { receiverAddressMapping, receiverMergeVariableMapping };
};

const UploadContactsDialog = ({
    onClose,
    open,
    sampleURL,
    onCompleted,
    csvHeaderOptions,
}: UploadContactsDialogProps) => {
    const { dispatchError, dispatchInfo } = useNotificationContext();
    const listImportService = useMailingListImportService();
    const listService = useMailingListService();

    const [file, setFile] = useState<File | null>(null);
    const [processingStage, setProcessingStage] = useState<
        'verifying_addresses' | 'creating_contacts' | null
    >(null);
    const [csvData, setCSVData] = useState<CSVData>({
        invalidRowCount: 0,
        validRowCount: 0,
        errors: [],
        headers: [],
    });

    const handleFileChange = async (file: File | null) => {
        if (!file) {
            setFile(null);
            return;
        }

        try {
            const data = await parseCSV(file, csvHeaderOptions);
            setCSVData(data);
            setFile(file);
        } catch (e) {
            console.error(e);
            setFile(null);
            dispatchError('Something went wrong while parsing your file.');
        }
    };

    const createMailingListImport = useCallback(async () => {
        if (!file) {
            // We know we should have a file at this point, assert as so
            throw new Error(
                'Something went wrong while processing your mailing list import.'
            );
        }

        const { receiverAddressMapping, receiverMergeVariableMapping } =
            generateReceiverMappings(csvData.headers);

        let mailingListImport = await listImportService.create({
            file,
            fileType: FileType.CSV,
            receiverAddressMapping,
            receiverMergeVariableMapping,
        });

        // Approx 2 hours of time
        const MAX_ATTEMPTS = 15_000;
        for (let i = 0; i < MAX_ATTEMPTS; ++i) {
            mailingListImport = await listImportService.get(
                mailingListImport.id
            );

            if (
                mailingListImport.status ===
                MailingListImportProcessStatus.COMPLETED
            ) {
                return mailingListImport;
            }

            if (
                mailingListImport.status ===
                MailingListImportProcessStatus.CHANGES_REQUIRED
            ) {
                throw new Error(
                    mailingListImport.errors
                        .map((err) => err.message)
                        .join('\n')
                );
            }

            await new Promise((res) => setTimeout(res, 250));
        }

        throw new Error(
            'Mailing list processing took too long. Please try again later.'
        );
    }, [listImportService, file, csvData.headers]);

    const processRows = useCallback(async () => {
        if (!file || !csvData.validRowCount || csvData.errors.length) {
            dispatchError('A file containing valid contacts is required.');
        }

        setProcessingStage('verifying_addresses');

        try {
            const mailingListImport = await createMailingListImport();

            if (mailingListImport.notes.length) {
                dispatchInfo(
                    mailingListImport.notes
                        .map((note) => note.message)
                        .join('\n')
                );
            }

            setProcessingStage('creating_contacts');

            const mailingList = await listService.create({
                metadata: { postgrid_dashboard: '' },
            });
            await listService.createJob(mailingList.id, {
                addMailingListImports: [mailingListImport.id],
            });

            const MAX_ATTEMPTS = 15_000;
            for (let i = 0; i < MAX_ATTEMPTS; ++i) {
                const processingList = await listService.get(mailingList.id);

                if (
                    processingList.status === MailingListProcessStatus.COMPLETED
                ) {
                    onCompleted({
                        mailingListImport,
                        mailingList,
                    });

                    setFile(null);
                    setProcessingStage(null);
                    return;
                }

                await new Promise((res) => setTimeout(res, 250));
            }

            throw new Error(
                'Mailing list processing took too long. Please try again later.'
            );
        } catch (e) {
            dispatchError((e as Error).message);
            setFile(null);
            setProcessingStage(null);
        }
    }, [
        dispatchInfo,
        createMailingListImport,
        csvData,
        file,
        dispatchError,
        listService,
        onCompleted,
    ]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            data-testid="upload-contacts-dialog"
        >
            <DialogContent>
                <DialogTitle>
                    <Typography variant="h5" component="span">
                        Upload a CSV File
                    </Typography>
                </DialogTitle>
                <DialogContentText>
                    <Typography component="span">
                        You can create a large number of contacts at once by
                        uploading a CSV file. Download a sample CSV file{' '}
                        <Link
                            rel="noopener"
                            target="_blank"
                            href={
                                sampleURL ||
                                'https://docs.google.com/spreadsheets/d/1Ke7m8X-IyTVTyZ0vpVgJokdtSrsIxKv-o5SjeRPHSYU/edit?usp=sharing'
                            }
                        >
                            here
                        </Link>
                        . Note that you can add your own columns and those will
                        be supplied as variable data to your orders.
                    </Typography>
                </DialogContentText>
                <FileUpload
                    label="Upload CSV File"
                    accept="text/csv"
                    file={file}
                    setFile={setFile}
                    onFileChange={handleFileChange}
                    disabled={!!processingStage}
                />
                {file && (
                    <RowErrors
                        validRowCount={csvData.validRowCount}
                        invalidRowCount={csvData.invalidRowCount}
                        errors={csvData.errors}
                    />
                )}
                {processingStage && (
                    <>
                        <Typography
                            color="primary"
                            sx={{
                                textAlign: 'center',
                                fontSize: 14,
                                fontWeight: 500,
                            }}
                        >
                            {processingStage === 'verifying_addresses'
                                ? 'Verifying Addresses'
                                : 'Creating Contacts'}
                        </Typography>
                        <LinearProgress color="primary" />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                            size="large"
                            fullWidth
                            disabled={!!processingStage}
                            data-testid="cancel-upload-contacts-button"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async (e) => {
                                await processRows();
                                onClose(e);
                            }}
                            size="large"
                            fullWidth
                            disabled={
                                !!processingStage ||
                                csvData.validRowCount <= 0 ||
                                csvData.errors.length > 0 ||
                                !file
                            }
                            data-testid="upload-contacts-button"
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default UploadContactsDialog;
