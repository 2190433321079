import { useState, useCallback } from 'react';

import type { ListParams, ListResponse } from '../services/util';
import {
    type Campaign,
    draftSendAndWaitForReadyCampaign,
    useService as useCampaignService,
} from '../services/Campaigns';
import { type Letter } from '../services/Letters';
import { type Postcard } from '../services/Postcards';
import { type Cheque } from '../services/Cheques';
import { type SelfMailer } from '../services/SelfMailers';
import type { BulkCreationState } from '../components/CreateOrderControls';
import type { OrderProfile } from '../services/OrderProfiles';
import useDeleteCampaignResources from './useDeleteCampaignResources';

type Order = Letter | Postcard | Cheque | SelfMailer;

const useCreateBulkOrders = () => {
    const campaignService = useCampaignService();
    const deleteCampaignResources = useDeleteCampaignResources();
    const [bulkCreationState, setBulkCreationState] =
        useState<BulkCreationState>(null);

    const createBulkOrders = useCallback(
        async ({
            mailingListID,
            defaultSenderID,
            sendDate,
            createProfileFn,
        }: {
            mailingListID: string;
            defaultSenderID?: string;
            sendDate: Date;
            createProfileFn: () => Promise<OrderProfile>;
            /** Used for generating the campaign report */
            listOrderFn: (params: ListParams) => Promise<ListResponse<Order>>;
        }) => {
            let campaign: Campaign | null = null;

            try {
                setBulkCreationState({ type: 'order_profile' });
                const profileID = (await createProfileFn()).id;
                setBulkCreationState({ type: 'campaign' });

                const profileType = profileID.startsWith('letter')
                    ? 'letterProfile'
                    : profileID.startsWith('postcard')
                    ? 'postcardProfile'
                    : profileID.startsWith('cheque')
                    ? 'chequeProfile'
                    : 'selfMailerProfile';

                campaign = await campaignService.create({
                    [profileType]: profileID,
                    mailingList: mailingListID,
                    defaultSenderContact: defaultSenderID,
                    metadata: { postgrid_dashboard: '' },
                });
            } catch (e) {
                setBulkCreationState({
                    type: 'error',
                    message: (e as Error).message,
                });
                throw e;
            }

            try {
                campaign = await draftSendAndWaitForReadyCampaign(
                    campaign.id,
                    sendDate,
                    campaignService
                );
            } catch (err) {
                await deleteCampaignResources(campaign, mailingListID);
                setBulkCreationState({
                    type: 'error',
                    message: (err as Error).message,
                });
                throw err;
            }

            try {
                if (!campaign.reportURL) {
                    throw new Error('Report not generated');
                }

                setBulkCreationState({
                    type: 'campaign_report',
                    report: campaign.reportURL,
                    fileName: `${campaign.id}.zip`,
                });
            } catch (err) {
                setBulkCreationState({
                    type: 'error',
                    message:
                        'Your campaign was sucessfully created but a report could not be generated.',
                    severity: 'warning',
                });
                throw err;
            }
        },
        [campaignService, deleteCampaignResources]
    );

    return { bulkCreationState, createBulkOrders };
};

export default useCreateBulkOrders;
