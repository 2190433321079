import React, { useContext } from 'react';
import posthog from 'posthog-js';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import Intercom, { update as updateIntercom } from '@intercom/messenger-js-sdk';

import {
    ThemeProvider,
    Theme,
    StyledEngineProvider,
    createTheme,
    adaptV4Theme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';

import {
    POSTHOG_KEY,
    OAUTH_AUDIENCE,
    OAUTH_CLIENT_ID,
    OAUTH_DOMAIN,
} from './config';

import { Context as AuthContext, Store as AuthStore } from './context/Auth';
import { PersistOrderStore } from './context/PersistOrderStore';
import { Store as ModeStore } from './context/Mode';
import { Store as LiveApprovedStore } from './context/LiveApproved';
import { Display as NotificationDisplay } from './context/Notification';
import { Display as BackgroundDownloadDisplay } from './context/BackgroundDownload';
import { EmbeddedRoutes, SSORoutes } from './routes';

import Dashboard from './pages/Dashboard';
import Signup from './pages/Signup';
import SignupCheckEmail from './pages/SignupCheckEmail';
import VerifyEmail from './pages/VerifyEmail';
import Login from './pages/Login';
import LoginSSO from './pages/LoginSSO';
import SignupSSO from './pages/SignupSSO';
import CompleteSignupSSO from './pages/CompleteSignupSSO';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Maintenance from './pages/Maintenance';
import EnterSignupInfoSSO from './pages/EnterSignupInfoSSO';
import Embed from './components/Embed';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

posthog.init(POSTHOG_KEY, {
    api_host: 'https://app.posthog.com',
});

ReactGA.initialize([
    { trackingId: 'G-WK8FXW2821' },
    { trackingId: 'G-SWV6BZ1KER' },
    { trackingId: 'G-R6Q7DW4DDB' },
    { trackingId: 'G-NX0QK8MD21' },
    { trackingId: 'G-9FVCJH1EJ9' },
]);

let bootedIntercom = false;

const theme = createTheme(
    // TODO: Move to v5 theme
    adaptV4Theme({
        typography: {
            fontFamily:
                'Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Ubuntu,sans-serif',
            h1: {
                fontFamily: 'Roboto',
            },
            h2: {
                fontFamily: 'Roboto',
            },
            h3: {
                fontFamily: 'Roboto',
            },
            h4: {
                fontFamily: 'Roboto',
            },
            h5: {
                fontFamily: 'Roboto',
            },
            h6: {
                fontFamily: 'Roboto',
                fontWeight: 400,
            },
            subtitle1: {
                fontFamily: 'Roboto',
            },
            subtitle2: {
                fontFamily: 'Poppins',
                fontSize: '15px',
                fontWeight: 500,
            },
            body1: {
                fontFamily: 'Roboto',
                color: '#000',
                fontSize: '15px',
            },
        },
        palette: {
            mode: 'light',
            background: {
                default: '#F7FAFC',
            },
            primary: {
                main: '#2f71eb',
                light: '#94BFFF',
                dark: '#7C8DB5',
            },
            secondary: {
                main: '#FD6270',
                contrastText: '#fff',
            },
            info: {
                main: '#2196f3',
                light: '#E0EBFB',
            },
            grey: {
                200: '#DBE9FB',
                A700: '#A3ACB9',
                A100: '#F0F6FD',
            },
        },
        overrides: {
            MuiListItemIcon: {
                root: {
                    minWidth: '35px',
                },
            },
            MuiTableCell: {
                root: {
                    fontFamily: 'Roboto',
                },
            },
            MuiInputLabel: {
                root: {
                    color: '#707070',
                },
            },
            MuiPaper: {
                elevation1: {
                    boxShadow: '0px 1px 2px 1px rgba(232,232,232,0.68)',
                },
            },
        },
    })
);

const OAuthProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <Auth0Provider
            domain={OAUTH_DOMAIN}
            clientId={OAUTH_CLIENT_ID}
            audience={OAUTH_AUDIENCE}
        >
            {children}
        </Auth0Provider>
    );
};

const RedirectAuthRoute = (props: RouteProps) => {
    const { state } = useContext(AuthContext);

    if (state.user) {
        return <Redirect to="/dashboard" />;
    }

    return <Route {...props} />;
};

const AuthRoute = (props: RouteProps) => {
    const { state } = useContext(AuthContext);
    const { isAuthenticated } = useAuth0();

    if (!state.user) {
        if (isAuthenticated) {
            return <Redirect to={SSORoutes.LOGIN} />;
        }
        return <Redirect to="/login" />;
    }

    posthog.identify(
        state.user.id,
        {},
        {
            name: state.user.name,
            email: state.user.email,
            phoneNumber: state.user.phoneNumber,
            accessedLiveMode: false,
            addedPaymentMethod: false,
            hasTestOrders: false,
            hasLiveOrders: false,
        }
    );

    const intercomParams = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'c9d33bc3',
        name: state.user?.name,
        email: state.user?.email,
        created_at: state.user
            ? state.user?.createdAt.getTime() / 1000
            : undefined,
        user_id: state.user?.id,
        user_hash: state.user?.intercomHash,
    };

    if (!bootedIntercom) {
        // this will boot up intercom
        Intercom(intercomParams);
    } else {
        updateIntercom(intercomParams);
    }

    bootedIntercom = true;

    return <Route {...props} />;
};

function App() {
    // ModeStore depends on the user being authenticated, so we have to put it underneath the AuthRoute.
    // More specifically, ModeStore calls useOrganization, which attempts to use the organization API on
    // the Base service. That fails if the user is not logged in, causing baseService to redirect to the login
    // page, and that loops.
    return (
        <OAuthProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router>
                        <AuthStore>
                            <NotificationDisplay>
                                <BackgroundDownloadDisplay>
                                    <Switch>
                                        <AuthRoute path="/dashboard">
                                            <ModeStore>
                                                <LiveApprovedStore>
                                                    <PersistOrderStore>
                                                        <Dashboard />
                                                    </PersistOrderStore>
                                                </LiveApprovedStore>
                                            </ModeStore>
                                        </AuthRoute>

                                        <Route
                                            path="/signup/:userID"
                                            component={Signup}
                                        />

                                        <Route
                                            path="/signup"
                                            component={Signup}
                                        />

                                        <Route
                                            path={SSORoutes.COMPLETE_SIGNUP}
                                            component={CompleteSignupSSO}
                                        />

                                        <Route
                                            path={SSORoutes.ENTER_SIGNUP_INFO}
                                            component={EnterSignupInfoSSO}
                                        />

                                        <Route
                                            path={SSORoutes.SIGNUP}
                                            component={SignupSSO}
                                        />

                                        <Route
                                            path="/signup_check_email"
                                            component={SignupCheckEmail}
                                        />

                                        <Route
                                            path={SSORoutes.VERIFY_EMAIL}
                                            render={() => (
                                                <VerifyEmail
                                                    redirectRoute={
                                                        SSORoutes.LOGIN
                                                    }
                                                />
                                            )}
                                        />

                                        <Route
                                            path="/verify_email/:token"
                                            render={() => (
                                                <VerifyEmail redirectRoute="/login" />
                                            )}
                                        />

                                        <RedirectAuthRoute
                                            path="/login/impersonate"
                                            render={() => {
                                                return (
                                                    <Login allowImpersonation />
                                                );
                                            }}
                                        />

                                        <RedirectAuthRoute
                                            path="/login"
                                            component={Login}
                                        />

                                        <RedirectAuthRoute
                                            path={SSORoutes.LOGIN}
                                            component={LoginSSO}
                                        />

                                        <Route
                                            path="/forgot_password"
                                            component={ForgotPassword}
                                        />

                                        <Route
                                            path="/reset_password/:token"
                                            component={ResetPassword}
                                        />

                                        <Route
                                            path="/maintenance"
                                            component={Maintenance}
                                        />

                                        <Route
                                            path={EmbeddedRoutes.BASE}
                                            component={Embed}
                                        />

                                        <Redirect from="/" to="/login" />
                                    </Switch>
                                </BackgroundDownloadDisplay>
                            </NotificationDisplay>
                        </AuthStore>
                    </Router>
                </ThemeProvider>
            </StyledEngineProvider>
        </OAuthProvider>
    );
}

export default App;
