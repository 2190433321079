import { useCallback, useEffect, useState } from 'react';
import {
    ContactOrMailingListData,
    isContactValue,
} from '../components/ContactOrCSV';
import { useNotificationContext } from '../context/Notification';
import { type Contact, useService } from '../services/Contacts';
import { useOrganization } from '../services/Organization';

const useCountryCodeForRecipients = (recipients: ContactOrMailingListData) => {
    const org = useOrganization([]);
    const { dispatchError } = useNotificationContext();
    const contactService = useService();
    const [countryCode, setCountryCode] = useState<string | null>(
        isContactValue(recipients) ? recipients.countryCode ?? null : null
    );

    const setCountryCodeForContact = useCallback(
        (contact: Contact) => {
            if (!contact.countryCode && !org?.defaultContactCountryCode) {
                throw new Error(
                    [
                        'A valid country code is needed for creating orders.',
                        'No country code was found for any contact and no default country code is specified for your organization.',
                        'Please ensure your contacts have a country code or reach out to support@postgrid.com to set a default country code for your organization.',
                    ].join(' ')
                );
            }

            setCountryCode(
                contact.countryCode ?? org?.defaultContactCountryCode ?? null
            );
        },
        [org]
    );

    useEffect(() => {
        if (!recipients) {
            return;
        }

        (async () => {
            try {
                if (isContactValue(recipients)) {
                    setCountryCodeForContact(recipients);
                    return;
                }

                const contacts = await contactService.list({
                    skip: 0,
                    limit: 1,
                    search: `{"mailingLists": "${recipients.mailingList.id}"}`,
                });

                if (!contacts.data.length) {
                    throw new Error('No contacts found for the uploaded list');
                }

                setCountryCodeForContact(contacts.data[0]);
            } catch (e) {
                dispatchError((e as Error).message);
                setCountryCode(null);
            }
        })();
    }, [
        org,
        recipients,
        contactService,
        dispatchError,
        setCountryCodeForContact,
    ]);

    return countryCode;
};

export default useCountryCodeForRecipients;
