import React, { FormEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// Contexts/Services/Hooks
import { formatMergeVariables } from '../services/util';
import { useOrganization } from '../services/Organization';
import { useNotificationContext } from '../context/Notification';
import { useModeContext } from '../context/Mode';
import { OrderMailingClass } from '../services/Base';
import createObjRefContext from '../context/RefContext';
import type { Contact } from '../services/Contacts';
import type { Template } from '../services/Templates';
import { useSelfMailerProfileService } from '../services/OrderProfiles';
import { mailingClassFromLegacyExpressExtraServiceOptions } from '../services/Orders';
import { useTemplateVars, useDefaultVars } from '../hooks/useMergeVars';
import useCountryCodeForRecipients from '../hooks/useCountryCodeForRecipients';
import {
    SelfMailerSize,
    useService as useSelfMailersService,
} from '../services/SelfMailers';
import { useRegisterCreateOrderResetFunction } from '../hooks/useRegisterCreateOrderResetFunction';
import useCreateBulkOrders from '../hooks/useCreateBulkOrders';
// Constants
import { SelfMailerRoutes } from '../routes';
// Components
// MUI components
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Grid, { GridSize } from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
// Our components
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import ContactOrCSV, {
    ContactOrMailingListData,
    isMailingListValue,
} from '../components/ContactOrCSV';
import ContactInput from '../components/ContactInput';
import SelectTemplate from '../components/SelectTemplate';
import FileUpload from '../components/FileUpload';
import MailingClassSelector, {
    countryCodeToCarrier,
} from '../components/MailingClassSelector';
import SendDateComponent, { minDate } from '../components/SendDate';
import ExpressDeliveryCheckbox from '../components/ExpressDeliveryCheckbox';
import MergeVariablesInput from '../components/MergeVariablesInput';
import CreateOrderControls, {
    type BulkCreationState,
} from '../components/CreateOrderControls';

const INITIAL_STATE = {
    description: '',
    express: false,
    sendDate: minDate(),
    mailingClass: OrderMailingClass.FIRST_CLASS,
    size: SelfMailerSize['BIFOLD_8.5X11'],
    toContactOrMailingListData: null as ContactOrMailingListData,
    from: null as Contact | null,
    insideTemplate: null as Template | null,
    outsideTemplate: null as Template | null,
    file: null as File | null,
    mergeVariables: {} as Record<string, string>,
    loading: false,
};

export const { StoreProvider, useStore, useGetStoreSnapshot } =
    createObjRefContext(INITIAL_STATE);

const useStoreAndShouldDisable = <Selected,>(
    selector: (store: typeof INITIAL_STATE) => Selected
) => {
    return useStore((store) => ({
        ...selector(store),
        disabled: store.loading,
    }));
};

const DescriptionField = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        description: store.description,
    }));

    return (
        <TextField
            variant="outlined"
            label="Description"
            fullWidth
            value={store.description}
            disabled={store.disabled}
            onChange={(e) => setStore({ description: e.target.value })}
        />
    );
};

const ToContacts = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        value: store.toContactOrMailingListData,
    }));

    return (
        <ContactOrCSV
            label="To Contact"
            value={store.value}
            setValue={(toContactOrMailingList) =>
                setStore({ toContactOrMailingListData: toContactOrMailingList })
            }
            disabled={store.disabled}
            required
        />
    );
};

const FromContact = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        from: store.from,
    }));

    return (
        <ContactInput
            label="From Contact"
            contact={store.from}
            setContact={(contact) => setStore({ from: contact })}
            disabled={store.disabled}
            required
        />
    );
};

const InsideTemplate = () => {
    const [{ insideTemplate, file, disabled }, setStore] =
        useStoreAndShouldDisable((store) => ({
            insideTemplate: store.insideTemplate,
            file: store.file,
        }));

    return (
        <SelectTemplate
            label="Inside Template"
            template={insideTemplate}
            setTemplate={(t) => setStore({ insideTemplate: t })}
            required={!file}
            disabled={disabled || !!file}
        />
    );
};

const OutsideTemplate = () => {
    const [{ outsideTemplate, file, disabled }, setStore] =
        useStoreAndShouldDisable((store) => ({
            outsideTemplate: store.outsideTemplate,
            file: store.file,
        }));

    return (
        <SelectTemplate
            label="Outside Template"
            template={outsideTemplate}
            setTemplate={(t) => setStore({ outsideTemplate: t })}
            required={!file}
            disabled={disabled || !!file}
        />
    );
};

const PDF = () => {
    const [{ insideTemplate, file, outsideTemplate, disabled }, setStore] =
        useStoreAndShouldDisable((store) => ({
            insideTemplate: store.insideTemplate,
            outsideTemplate: store.outsideTemplate,
            file: store.file,
        }));

    return (
        <FileUpload
            accept="application/pdf"
            label="Upload a PDF"
            file={file}
            setFile={(f) => setStore({ file: f })}
            required={!insideTemplate && !outsideTemplate}
            disabled={disabled || !!insideTemplate || !!outsideTemplate}
        />
    );
};

const MailingClass = ({ xs }: { xs: GridSize }) => {
    const [{ mailingClass, disabled, express, to }, setStore] =
        useStoreAndShouldDisable((store) => ({
            mailingClass: store.mailingClass,
            express: store.express,
            to: store.toContactOrMailingListData,
        }));
    const toCountryCode = useCountryCodeForRecipients(to);

    return (
        <MailingClassSelector
            xs={xs}
            mailingClass={mailingClass}
            onChange={(mc) => setStore({ mailingClass: mc })}
            carrierDisclaimer={
                toCountryCode
                    ? countryCodeToCarrier(toCountryCode) ?? undefined
                    : undefined
            }
            disabled={disabled || !!express}
        />
    );
};

const SendDate = ({ xs }: { xs: GridSize }) => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        sendDate: store.sendDate,
    }));
    const history = useHistory();
    const org = useOrganization([history.location]);

    return (
        <SendDateComponent
            xs={xs}
            setSendDate={(sd) => setStore({ sendDate: sd })}
            sendDate={store.sendDate}
            disabled={store.disabled}
            showSubscriptionPopup={!org?.stripeSubscription}
        />
    );
};

const Size = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        size: store.size,
    }));

    return (
        <FormControl component="fieldset">
            <Typography>Size in Inches (Width x Height)</Typography>
            <RadioGroup
                row
                value={store.size}
                onChange={(_, v) => setStore({ size: v as SelfMailerSize })}
            >
                <FormControlLabel
                    value={SelfMailerSize['BIFOLD_8.5X11']}
                    control={<Radio color="primary" />}
                    disabled={store.disabled}
                    label="8.5x11 (Bifold)"
                />
            </RadioGroup>
        </FormControl>
    );
};

const Express = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        express: store.express,
    }));

    return (
        <ExpressDeliveryCheckbox
            checked={store.express}
            disabled={store.disabled}
            setChecked={(c) => setStore({ express: c })}
        />
    );
};

const MergeVariables = () => {
    const [
        { insideTemplate, outsideTemplate, mergeVariables, to, from, disabled },
        setStore,
    ] = useStoreAndShouldDisable((store) => ({
        insideTemplate: store.insideTemplate,
        outsideTemplate: store.outsideTemplate,
        mergeVariables: store.mergeVariables,
        to: store.toContactOrMailingListData,
        from: store.from,
    }));

    const templateVars = useTemplateVars(insideTemplate, outsideTemplate);
    const defaultVars = useDefaultVars(to, from);

    return (
        <Collapse in={templateVars.length > 0}>
            <MergeVariablesInput
                templateVars={templateVars}
                mergeVars={mergeVariables}
                setMergeVars={(v) => setStore({ mergeVariables: v })}
                defaultVars={defaultVars}
                disabled={disabled}
            />
        </Collapse>
    );
};

const Controls = ({
    bulkCreationState,
}: {
    bulkCreationState: BulkCreationState;
}) => {
    const [store] = useStore((store) => ({
        loading: store.loading,
        mailingList: isMailingListValue(store.toContactOrMailingListData)
            ? store.toContactOrMailingListData
            : null,
    }));

    return (
        <CreateOrderControls
            disabled={store.loading}
            bulkCreationState={bulkCreationState}
        />
    );
};

const CreateSelfMailerForm = () => {
    const [, setStore] = useStore(() => {});

    const { dispatchSuccess, dispatchError } = useNotificationContext();
    const service = useSelfMailersService();
    const getSnapshot = useGetStoreSnapshot();
    const history = useHistory();
    const profileService = useSelfMailerProfileService();
    const { bulkCreationState, createBulkOrders } = useCreateBulkOrders();

    const resetState = useCallback(() => setStore(INITIAL_STATE), [setStore]);
    useRegisterCreateOrderResetFunction(resetState);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const store = getSnapshot();

        if (!store.toContactOrMailingListData) {
            dispatchError('Recipient required');
            return;
        }

        if (!store.from) {
            dispatchError('Sender required');
            return;
        }

        setStore({ loading: true });

        if (isMailingListValue(store.toContactOrMailingListData)) {
            const mergeVariables = formatMergeVariables(store.mergeVariables, {
                uploadedCSV: true,
            });

            try {
                await createBulkOrders({
                    mailingListID:
                        store.toContactOrMailingListData.mailingList.id,
                    listOrderFn: service.list.bind(service),
                    sendDate: store.sendDate,
                    defaultSenderID: store.from.id,
                    createProfileFn: () => {
                        return profileService.create({
                            pdf: store.file ?? undefined,
                            size: store.size,
                            mailingClass:
                                mailingClassFromLegacyExpressExtraServiceOptions(
                                    store.mailingClass,
                                    store.express,
                                    null
                                ),
                            description: store.description,
                            insideTemplate: store.insideTemplate?.id,
                            outsideTemplate: store.outsideTemplate?.id,
                            mergeVariables: store.file
                                ? undefined
                                : mergeVariables,
                        });
                    },
                });
                dispatchSuccess('Created self mailers.');
            } catch (e) {
                // Clear the uploaded list as we delete campaign resources on
                // failures.
                setStore({ loading: false, toContactOrMailingListData: null });
                dispatchError((e as Error).message);
            }
            return;
        }

        try {
            await service.create({
                ...store,
                pdf: store.file ?? undefined,
                insideTemplate: store.insideTemplate?.id,
                outsideTemplate: store.outsideTemplate?.id,
                from: store.from!.id,
                to: store.toContactOrMailingListData.id,
                mergeVariables: formatMergeVariables(store.mergeVariables),
            });
            dispatchSuccess('Created Self Mailer.');
            history.push(SelfMailerRoutes.HOME);
        } catch (e) {
            dispatchError((e as Error).message);
        } finally {
            setStore({ loading: false });
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {/* HACK(from CreatePostcard): Lil box for padding for sizes */}
                    <Box height={13}></Box>
                    <DescriptionField />
                </Grid>
                <Grid item xs={6}>
                    <Size />
                </Grid>
                <Grid item xs={6}>
                    <ToContacts />
                </Grid>
                <Grid item xs={6}>
                    <FromContact />
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <InsideTemplate />
                        </Grid>
                        <Grid item xs>
                            <OutsideTemplate />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <PDF />
                </Grid>
                <Grid item xs={12}>
                    <MergeVariables />
                </Grid>
                {/* Has a `<Grid />` in the underlying component */}
                <MailingClass xs={6} />
                {/* Has a `<Grid />` in the underlying component */}
                <SendDate xs={6} />
                <Grid item>
                    <Express />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controls bulkCreationState={bulkCreationState} />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

const CreateSelfMailer = () => {
    const { live } = useModeContext();

    return (
        <>
            <TopNav />
            <GridPaper direction="column" spacing={2}>
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC">
                        <Typography variant="h5" gutterBottom>
                            Create a Self Mailer
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item direction="column">
                    <Grid item>
                        <Box my={2}>
                            {live ? (
                                <Alert variant="outlined" color="warning">
                                    You are in live mode so this self mailer
                                    will be printed and delivered.
                                </Alert>
                            ) : (
                                <Alert variant="outlined" color="info">
                                    You are in test mode so this self mailer
                                    will not actually get sent out.
                                </Alert>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <CreateSelfMailerForm />
                    </Grid>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateSelfMailer;
