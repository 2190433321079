import { useMemo } from 'react';

import {
    useService as useBaseService,
    type Resource,
    type OrderMailingClass,
    type ResourceCreateParams,
    ExtraServiceMailingClass,
} from './Base';
import { type Thumbnail, type MergeVariables, requestFormData } from './util';
import type {
    AddressPlacement,
    AttachedPDFPlacement,
    EnvelopeType,
    LetterSize,
} from './Letters';
import type { PostcardSize } from './Postcards';
import type { ChequeSize, CurrencyCode, LetterSettings } from './Cheques';
import type { Contact, CreateParams as CreateContactParams } from './Contacts';
import type { SelfMailerSize } from './SelfMailers';
import { Service as DeletableResourceService } from './DeletableResource';

interface BaseProfileCreateBody extends ResourceCreateParams {
    mailingClass?: OrderMailingClass;
}

interface LetterProfile extends Resource {
    object: 'letter_profile';
    mailingClass?: OrderMailingClass;
    size: LetterSize;
    color?: boolean;
    doubleSided?: boolean;
    envelopeType?: EnvelopeType;
    envelope?: string;
    perforatedPage?: number;
    addressPlacement?: AddressPlacement;
    returnEnvelope?: string;
    attachedPDF?: { file: string; placement: AttachedPDFPlacement };
    thumbnail?: Thumbnail;
    mergeVariables?: MergeVariables;
    template?: string;
    uploadedPDF?: string;
}

interface PostcardProfile extends Resource {
    object: 'postcard_profile';
    mailingClass?: Omit<OrderMailingClass, ExtraServiceMailingClass>;
    size: PostcardSize;
    mergeVariables?: MergeVariables;
    thumbnail?: Thumbnail;
    frontTemplate: string;
    backTemplate: string;
    uploadedPDF: string;
}

interface ChequeProfile extends Resource {
    object: 'cheque_profile';
    size: ChequeSize;
    bankAccount: string;
    currencyCode: CurrencyCode;
    memo?: string;
    logo?: string;
    message?: string;
    redirectTo?: Contact;
    letterHTML?: string;
    letterTemplate?: string;
    letterUploadedPDF?: string;
    letterSettings?: LetterSettings;
    mailingClass?: OrderMailingClass;
    mergeVariables?: MergeVariables;
}

interface SelfMailerProfile extends Resource {
    object: 'self_mailer_profile';
    size: SelfMailerSize;
    insideTemplate?: string;
    outsideTemplate?: string;
    uploadedPDF?: string;
    mailingClass?: Omit<OrderMailingClass, ExtraServiceMailingClass>;
    mergeVariables?: MergeVariables;
}

interface CreateLetterProfileParams extends BaseProfileCreateBody {
    addressPlacement?: AddressPlacement;
    doubleSided?: boolean;
    color?: boolean;
    envelopeType?: EnvelopeType;
    envelope?: string;
    perforatedPage?: number;
    returnEnvelope?: string;
    size: LetterSize;
    attachedPDF?: { file: File | string; placement: AttachedPDFPlacement };
    template?: string;
    pdf?: File | string;
    mergeVariables?: MergeVariables;
}

type UpdateLetterProfileParams = Partial<CreateLetterProfileParams>;

interface CreatePostcardProfileParams
    extends Omit<BaseProfileCreateBody, 'mailingClass'> {
    size: PostcardSize;
    mailingClass?: Omit<OrderMailingClass, ExtraServiceMailingClass>;
    frontTemplate?: string;
    backTemplate?: string;
    pdf?: File | string;
    mergeVariables?: MergeVariables;
}

type UpdatePostcardProfileParams = Partial<CreatePostcardProfileParams>;

interface CreateChequeProfileParams extends BaseProfileCreateBody {
    redirectTo?: CreateContactParams | string;
    bankAccount: string;
    memo?: string;
    message?: string;
    letterHTML?: string;
    letterTemplate?: string;
    letterPDF?: File | string;
    logo?: string;
    currencyCode?: CurrencyCode;
    size: ChequeSize;
    letterSettings?: LetterSettings;
    mergeVariables?: MergeVariables;
}

type UpdateChequeProfileParams = Partial<CreateChequeProfileParams>;

interface CreateSelfMailerProfileParams
    extends Omit<BaseProfileCreateBody, 'mailingClass'> {
    mailingClass?: Omit<OrderMailingClass, ExtraServiceMailingClass>;
    size: SelfMailerSize;
    insideTemplate?: string;
    outsideTemplate?: string;
    pdf?: File | string;
    mergeVariables?: MergeVariables;
}

type UpdateSelfMailerProfileParams = Partial<CreateSelfMailerProfileParams>;

export type OrderProfile =
    | LetterProfile
    | ChequeProfile
    | PostcardProfile
    | SelfMailerProfile;

const createUseService = <
    T extends Resource,
    CreateParams extends {},
    UpdateParams extends {}
>(
    route: string
) => {
    class Service extends DeletableResourceService<T> {
        create(params: CreateParams) {
            // Use form data as a default to allow sending PDFs correctly
            const data = requestFormData(params);
            data.set('metadata[postgrid_dashboard]', '');

            return this.base.fetchAPI<T>(`/${this.route}`, {
                method: 'POST',
                body: data,
            });
        }

        update(id: string, params: UpdateParams) {
            return this.base.fetchAPI<T>(`/${this.route}/${id}`, {
                method: 'POST',
                body: params,
            });
        }
    }

    return () => {
        const base = useBaseService();

        return useMemo(
            () => new Service(base, `order_profiles/${route}`),
            [base]
        );
    };
};

export const useLetterProfileService = createUseService<
    LetterProfile,
    CreateLetterProfileParams,
    UpdateLetterProfileParams
>('letters');
export const usePostcardProfileService = createUseService<
    PostcardProfile,
    CreatePostcardProfileParams,
    UpdatePostcardProfileParams
>('postcards');
export const useChequeProfileService = createUseService<
    ChequeProfile,
    CreateChequeProfileParams,
    UpdateChequeProfileParams
>('cheques');
export const useSelfMailerProfileService = createUseService<
    SelfMailerProfile,
    CreateSelfMailerProfileParams,
    UpdateSelfMailerProfileParams
>('self_mailers');
