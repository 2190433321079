import React, { FormEvent, useCallback } from 'react';

import { LetterRoutes } from '../routes';

import { useModeContext } from '../context/Mode';
import createObjRefContext from '../context/RefContext';
import { useNotificationContext } from '../context/Notification';

import useCreateBulkOrders from '../hooks/useCreateBulkOrders';
import useCountryCodeForRecipients from '../hooks/useCountryCodeForRecipients';
import { useRegisterCreateOrderResetFunction } from '../hooks/useRegisterCreateOrderResetFunction';
import { useTemplateVars, useDefaultVars } from '../hooks/useMergeVars';
import { useHistory } from '../hooks/useHistory';

import { mailingClassFromLegacyExpressExtraServiceOptions } from '../services/Orders';
import type { ReturnEnvelope } from '../services/ReturnEnvelopes';
import type { Template } from '../services/Templates';
import { STANDARD_ENVELOPE } from '../services/CustomEnvelopes';
import { OrderExtraService, OrderMailingClass } from '../services/Base';
import {
    AddressPlacement,
    CreateParams,
    LetterSize,
    useService as useLettersService,
} from '../services/Letters';
import { Contact } from '../services/Contacts';
import { formatMergeVariables } from '../services/util';
import { Organization, useOrganization } from '../services/Organization';
import { useLetterProfileService } from '../services/OrderProfiles';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

import SelectTemplate from '../components/SelectTemplate';
import FileUpload from '../components/FileUpload';
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';
import ContactOrCSV, {
    type ContactOrMailingListData,
    isMailingListValue,
} from '../components/ContactOrCSV';
import ContactInput from '../components/ContactInput';
import SelectReturnEnvelope from '../components/SelectReturnEnvelope';
import SelectLetterSize from '../components/SelectLetterSize';
import MergeVariablesInput from '../components/MergeVariablesInput';
import ExpressDeliveryCheckbox from '../components/ExpressDeliveryCheckbox';
import ExtraServiceSelector from '../components/ExtraServiceSelector';
import MailingClassSelector, {
    countryCodeToCarrier,
} from '../components/MailingClassSelector';
import SendDate, { minDate } from '../components/SendDate';
import SelectCustomEnvelope from '../components/SelectCustomEnvelope';
import CreateOrderControls, {
    BulkCreationState,
} from '../components/CreateOrderControls';

const INITIAL_STATE = {
    description: '',
    toContactOrMailingListData: null as ContactOrMailingListData,
    from: null as Contact | null,
    template: null as Template | null,
    file: null as File | null,
    mergeVariables: {} as Record<string, string>,
    color: false,
    doubleSided: false,
    insertBlankPage: false,
    perforateFirstPage: false,
    returnEnvelope: null as ReturnEnvelope | null,
    envelope: STANDARD_ENVELOPE,
    mailingClass: OrderMailingClass.FIRST_CLASS,
    extraService: null as OrderExtraService | null,
    express: false,
    sendDate: minDate(),
    size: null as LetterSize | null,
    loading: false,
};

export const { useStore, StoreProvider, useGetStoreSnapshot } =
    createObjRefContext(INITIAL_STATE);

const useStoreAndShouldDisable = <Selected,>(
    selector: (store: typeof INITIAL_STATE) => Selected
) => {
    return useStore((store) => ({
        ...selector(store),
        disabled: store.loading,
    }));
};

const FormControlledCheckbox = ({
    label,
    checked,
    onChange,
    dataTestID,
    disabled,
}: {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    dataTestID?: string;
    disabled?: boolean;
}) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    checked={checked}
                    disabled={disabled}
                    onChange={(e) => {
                        onChange(e.target.checked);
                    }}
                    inputProps={
                        {
                            'data-testid': dataTestID ?? 'letter-blank-page',
                        } as React.InputHTMLAttributes<HTMLInputElement>
                    }
                />
            }
            label={label}
        />
    );
};

const Description = () => {
    const [state, setStore] = useStoreAndShouldDisable((store) => ({
        description: store.description,
    }));

    return (
        <TextField
            variant="outlined"
            label="Description"
            fullWidth
            value={state.description}
            disabled={state.disabled}
            onChange={(e) => {
                setStore({ description: e.target.value });
            }}
            inputProps={{
                'data-testid': 'letter-description',
            }}
        />
    );
};

const ToContacts = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        toContactOrMailingList: store.toContactOrMailingListData,
    }));

    return (
        <ContactOrCSV
            label="To Contact"
            value={store.toContactOrMailingList}
            disabled={store.disabled}
            setValue={(toContactOrMailingList) =>
                setStore({ toContactOrMailingListData: toContactOrMailingList })
            }
            textFieldTestId="letter-to-contact"
            required
        />
    );
};

const FromContact = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        from: store.from,
    }));

    return (
        <ContactInput
            label="From Contact"
            contact={store.from}
            disabled={store.disabled}
            setContact={(from) => setStore({ from })}
            textFieldTestId="letter-from-contact"
            required
        />
    );
};

const LetterTemplate = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        template: store.template,
        file: store.file,
    }));

    return (
        <SelectTemplate
            label="Select a Template"
            template={store.template}
            setTemplate={(template) => setStore({ template })}
            required={!store.file}
            disabled={store.disabled || !!store.file}
            textFieldTestId="letter-template"
        />
    );
};

const PDFUpload = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        template: store.template,
        file: store.file,
    }));

    return (
        <FileUpload
            accept="application/pdf"
            label="Upload a PDF"
            file={store.file}
            setFile={(file) => setStore({ file })}
            required={!store.template}
            disabled={store.disabled || !!store.template}
        />
    );
};

const LetterReturnEnvelope = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        returnEnvelope: store.returnEnvelope,
    }));

    return (
        <SelectReturnEnvelope
            label="Select a Return Envelope"
            returnEnvelope={store.returnEnvelope}
            disabled={store.disabled}
            setReturnEnvelope={(r) => setStore({ returnEnvelope: r })}
            textFieldTestID="letter-return-envelope"
        />
    );
};

const LetterCustomEnvelope = ({ org }: { org: Organization }) => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        envelope: store.envelope,
    }));

    return (
        <SelectCustomEnvelope
            org={org}
            label="Select Envelope"
            customEnvelope={store.envelope}
            setCustomEnvelope={(envelope) => setStore({ envelope })}
            setLoading={(loading) => setStore({ loading })}
            textFieldTestID="letter-custom-envelope"
            disabled={store.disabled}
            required
        />
    );
};

const ExtraService = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        extraService: store.extraService,
        express: store.express,
    }));

    return (
        <ExtraServiceSelector
            xs={3}
            extraService={store.extraService ?? ''}
            onChange={(e) => setStore({ extraService: e || null })}
            disabled={!!store.disabled || !!store.express}
            selectTestID="letter-extra-service"
        />
    );
};

const Size = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        size: store.size,
        toRecipients: store.toContactOrMailingListData,
    }));
    const recipientCountryCode = useCountryCodeForRecipients(
        store.toRecipients
    );

    return (
        <SelectLetterSize
            xs={3}
            size={store.size ?? ''}
            onChange={(size) => setStore({ size: size || null })}
            destinationCountryCode={recipientCountryCode ?? undefined}
            disabled={store.disabled}
            selectTestID="letter-size"
        />
    );
};

const MailingClass = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        mailingClass: store.mailingClass,
        express: store.express,
        to: store.toContactOrMailingListData,
    }));
    const toCountryCode = useCountryCodeForRecipients(store.to);

    return (
        <MailingClassSelector
            xs={3}
            mailingClass={store.mailingClass}
            onChange={(mailingClass) => setStore({ mailingClass })}
            disabled={!!store.disabled || !!store.express}
            selectTestID="letter-mailing-class"
            carrierDisclaimer={
                toCountryCode
                    ? countryCodeToCarrier(toCountryCode) ?? undefined
                    : undefined
            }
        />
    );
};

const LetterSendDate = ({ isSubscribed }: { isSubscribed: boolean }) => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        sendDate: store.sendDate,
    }));

    return (
        <SendDate
            xs={3}
            setSendDate={(s) => setStore({ sendDate: s })}
            sendDate={store.sendDate}
            showSubscriptionPopup={!isSubscribed}
            disabled={store.disabled}
        />
    );
};

const MergeVariables = () => {
    const [{ template, mergeVariables, to, from, disabled }, setStore] =
        useStoreAndShouldDisable((store) => ({
            template: store.template,
            mergeVariables: store.mergeVariables,
            to: store.toContactOrMailingListData,
            from: store.from,
        }));

    // TODO: Fix this overwriting already entered values for merge vars
    const templateVars = useTemplateVars(template);
    const defaultVars = useDefaultVars(to, from);

    return (
        <Collapse in={templateVars.length > 0}>
            <MergeVariablesInput
                templateVars={templateVars}
                mergeVars={mergeVariables}
                setMergeVars={(v) => setStore({ mergeVariables: v })}
                defaultVars={defaultVars}
                disabled={disabled}
            />
        </Collapse>
    );
};

const Color = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        color: store.color,
    }));

    return (
        <FormControlledCheckbox
            label="Color"
            checked={store.color}
            onChange={(e) => {
                setStore({ color: e });
            }}
            dataTestID="letter-color"
            disabled={store.disabled}
        />
    );
};

const DoubleSided = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        doubleSided: store.doubleSided,
    }));

    return (
        <FormControlledCheckbox
            label="Double Sided"
            checked={store.doubleSided}
            disabled={store.disabled}
            onChange={(e) => {
                setStore({ doubleSided: e });
            }}
            dataTestID="letter-double-sided"
        />
    );
};

const InsertBlankPage = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        insertBlankPage: store.insertBlankPage,
    }));

    return (
        <FormControlledCheckbox
            label="Insert Blank Page for Address"
            checked={store.insertBlankPage}
            disabled={store.disabled}
            onChange={(c) => setStore({ insertBlankPage: c })}
            dataTestID="letter-blank-page"
        />
    );
};

const PerforateFirstPage = () => {
    const [store, setStore] = useStoreAndShouldDisable((store) => ({
        perforateFirstPage: store.perforateFirstPage,
    }));

    return (
        <FormControlledCheckbox
            label="Perforate First Page"
            checked={store.perforateFirstPage}
            disabled={store.disabled}
            onChange={(c) => setStore({ perforateFirstPage: c })}
            dataTestID="letter-perforate"
        />
    );
};

const Express = () => {
    const [store, setStore] = useStore((store) => ({
        express: store.express,
        disabled: store.loading || !!store.extraService,
    }));

    return (
        <ExpressDeliveryCheckbox
            checked={store.express}
            setChecked={(c) => setStore({ express: c })}
            disabled={store.disabled}
            checkboxTestID="letter-express"
        />
    );
};

const Controls = ({
    bulkCreationState,
}: {
    bulkCreationState: BulkCreationState;
}) => {
    const [store] = useStoreAndShouldDisable((store) => ({
        mailingList: isMailingListValue(store.toContactOrMailingListData)
            ? store.toContactOrMailingListData
            : null,
    }));

    return (
        <CreateOrderControls
            disabled={store.disabled}
            bulkCreationState={bulkCreationState}
        />
    );
};

const CreateLetter = () => {
    const history = useHistory();
    const org = useOrganization([history.location]);
    const service = useLettersService();
    const profileService = useLetterProfileService();
    const { bulkCreationState, createBulkOrders } = useCreateBulkOrders();
    const { live } = useModeContext();
    const { dispatchSuccess, dispatchError } = useNotificationContext();

    const getSnapshot = useGetStoreSnapshot();
    const [, setStore] = useStore(() => {});

    const resetState = useCallback(() => setStore(INITIAL_STATE), [setStore]);
    useRegisterCreateOrderResetFunction(resetState);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const store = getSnapshot();
        if (
            !store.toContactOrMailingListData ||
            !store.from ||
            (!store.template && !store.file) ||
            !store.size
        ) {
            // This should be impossible given that both fields are required
            return;
        }

        if (isMailingListValue(store.toContactOrMailingListData)) {
            const mergeVariables = formatMergeVariables(store.mergeVariables, {
                uploadedCSV: true,
            });

            try {
                setStore({ loading: true });
                await createBulkOrders({
                    mailingListID:
                        store.toContactOrMailingListData.mailingList.id,
                    defaultSenderID: store.from.id,
                    listOrderFn: service.list.bind(service),
                    sendDate: store.sendDate,
                    createProfileFn: () => {
                        return profileService.create({
                            // We validate above that this is present
                            size: store.size!,
                            envelope:
                                store.envelope?.id === STANDARD_ENVELOPE.id
                                    ? undefined
                                    : store.envelope?.id,
                            returnEnvelope: store.returnEnvelope?.id,
                            template: store.template?.id,
                            description:
                                store.description ??
                                store.template?.description,
                            doubleSided: store.doubleSided,
                            mailingClass:
                                mailingClassFromLegacyExpressExtraServiceOptions(
                                    store.mailingClass,
                                    store.express,
                                    store.extraService
                                ),
                            pdf: store.file ?? undefined,
                            color: store.color,
                            perforatedPage: store.perforateFirstPage
                                ? 1
                                : undefined,
                            addressPlacement: store.insertBlankPage
                                ? AddressPlacement.INSERT_BLANK_PAGE
                                : AddressPlacement.TOP_FIRST_PAGE,
                            mergeVariables: store.file
                                ? undefined
                                : mergeVariables,
                        });
                    },
                });
                dispatchSuccess('Created letters.');
            } catch (e) {
                // Clear the uploaded list as we delete campaign resources on
                // failures.
                setStore({
                    loading: false,
                    toContactOrMailingListData: null,
                });
                dispatchError((e as Error).message);
            }
            return;
        }

        setStore({ loading: true });
        const to = store.toContactOrMailingListData;

        const addressPlacement: AddressPlacement = store.insertBlankPage
            ? AddressPlacement.INSERT_BLANK_PAGE
            : AddressPlacement.TOP_FIRST_PAGE;

        const letter: CreateParams = {
            description: store.description || to.description,
            to: to.id,
            // Should be defined at time of calling this as the
            // snapshould will not change from under us since we
            // fetch it once
            from: store.from!.id,
            color: store.color,
            doubleSided: store.doubleSided,
            addressPlacement,
            envelope:
                store.envelope?.id === STANDARD_ENVELOPE.id
                    ? undefined
                    : store.envelope?.id,
            returnEnvelope: store.returnEnvelope?.id,
            extraService: store.extraService ?? undefined,
            express: store.express,
            mailingClass: store.express ? undefined : store.mailingClass,
            perforatedPage: store.perforateFirstPage ? 1 : undefined,
            sendDate: store.sendDate,
            size: store.size ?? '',
        };

        const mergeVariables = formatMergeVariables(store.mergeVariables, {
            toMetadata: to.metadata,
            uploadedCSV: false,
        });

        try {
            if (store.template) {
                await service.create({
                    ...letter,
                    template: store.template.id,
                    mergeVariables,
                });
            } else {
                await service.createUploadPDF({
                    ...letter,
                    // If we didn't have a template, then we must have had
                    // a file
                    pdf: store.file!,
                });
            }
            dispatchSuccess('Created letter');
            history.push(LetterRoutes.HOME);
        } catch (e) {
            dispatchError((e as Error).message);
        } finally {
            setStore({ loading: false });
        }
    };

    return (
        <>
            <TopNav />
            <GridPaper direction="column" spacing={2}>
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC">
                        <Typography variant="h5" gutterBottom>
                            Create a Letter
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item direction="column">
                    <Grid item>
                        <Box my={2}>
                            {live ? (
                                <Alert variant="outlined" color="warning">
                                    You are in live mode so this letter will be
                                    printed and delivered.
                                </Alert>
                            ) : (
                                <Alert variant="outlined" color="info">
                                    You are in test mode so this letter will not
                                    actually get sent out.
                                </Alert>
                            )}
                        </Box>
                    </Grid>
                    <Grid item>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Description />
                                </Grid>
                                <Grid item xs={6}>
                                    <ToContacts />
                                </Grid>
                                <Grid item xs={6}>
                                    <FromContact />
                                </Grid>
                                <Grid item xs={6}>
                                    <LetterTemplate />
                                </Grid>
                                <Grid item xs={6}>
                                    <PDFUpload />
                                </Grid>
                                <Grid item xs={6}>
                                    <LetterReturnEnvelope />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* HACK/TODO: Not always available */}
                                    <LetterCustomEnvelope org={org!} />
                                </Grid>
                                <Size />
                                <ExtraService />
                                <MailingClass />
                                <LetterSendDate
                                    isSubscribed={!!org?.stripeSubscription}
                                />
                                <Grid item xs={12}>
                                    <MergeVariables />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid item>
                                        <Color />
                                    </Grid>
                                    <Grid item>
                                        <DoubleSided />
                                    </Grid>
                                    <Grid item>
                                        <InsertBlankPage />
                                    </Grid>
                                    <Grid item>
                                        <PerforateFirstPage />
                                    </Grid>
                                    <Express />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controls
                                        bulkCreationState={bulkCreationState}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreateLetter;
