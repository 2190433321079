import React from 'react';

import { OrderMailingClass } from '../services/Base';

import Grid, { GridProps } from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TooltipWrapper from './TooltipWrapper';
import StrictEnumSelect from './StrictEnumSelect';
import MailingClassExpressTooltip from './MailingClassExpressTooltip';

type Carrier = 'USPS' | 'CANPOST';
type MailingClassSelectorProps = Omit<GridProps, 'onChange'> & {
    mailingClass: OrderMailingClass;
    onChange: (service: OrderMailingClass) => void;
    disabled?: boolean;
    selectTestID?: string;
    carrierDisclaimer?: Carrier;
};

export const countryCodeToCarrier = (country: string): Carrier | null => {
    switch (country) {
        case 'US':
            return 'USPS';
        case 'CA':
            return 'CANPOST';
        default:
            return null;
    }
};

const MailingClassSelector = ({
    mailingClass,
    onChange,
    disabled,
    selectTestID,
    carrierDisclaimer,
    ...props
}: MailingClassSelectorProps) => {
    const displayDisclaimer =
        !!carrierDisclaimer &&
        mailingClass === OrderMailingClass.STANDARD_CLASS;

    return (
        <TooltipWrapper
            title={<MailingClassExpressTooltip />}
            disabled={disabled}
        >
            <Grid item {...props}>
                <StrictEnumSelect
                    title="Mailing Class"
                    valueLabels={[
                        [
                            OrderMailingClass.FIRST_CLASS,
                            'First Class (7-10 business days)',
                        ],
                        [
                            OrderMailingClass.STANDARD_CLASS,
                            'Standard Class (10-14 business days)',
                        ],
                    ]}
                    selectTestID={selectTestID}
                    onChange={onChange}
                    value={mailingClass}
                    disabled={disabled}
                />
                <Collapse
                    in={displayDisclaimer}
                    data-testid="carrier-disclaimer"
                >
                    <Alert severity="warning" style={{ marginTop: '12px' }}>
                        <AlertTitle>Disclaimer</AlertTitle>
                        {carrierDisclaimer === 'CANPOST'
                            ? 'Canada Post'
                            : 'USPS'}{' '}
                        requires non-marketing mail to be sent as First Class.
                        PostGrid will auto-convert such mailers to First Class,
                        with additional costs reflected in your invoice.
                    </Alert>
                </Collapse>
            </Grid>
        </TooltipWrapper>
    );
};

export default MailingClassSelector;
