import React, { useState, PropsWithoutRef } from 'react';

import type { Contact } from '../services/Contacts';
import type { MailingList } from '../services/MailingLists';
import type { MailingListImport } from '../services/MailingListImports';
import { useModal } from '../hooks/useModal';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import ContactInput from './ContactInput';
import UploadContactsDialog, {
    type HeaderOptions,
    type CompletedUploadData,
} from './UploadContactsDialog';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';

interface MailingListData {
    mailingList: MailingList;
    mailingListImport: MailingListImport;
}

export type ContactOrMailingListData = Contact | MailingListData | null;

export const isContactValue = (
    value: ContactOrMailingListData
): value is Contact => {
    return !!(value && !('mailingList' in value));
};

export const isMailingListValue = (
    value: ContactOrMailingListData
): value is MailingListData => {
    return !!(value && 'mailingList' in value);
};

const ContactOrCSV = ({
    label,
    required,
    value,
    setValue,
    sampleURL,
    textFieldTestId,
    disabled,
    csvHeaderOptions,
}: PropsWithoutRef<{
    label: string;
    value: ContactOrMailingListData;
    setValue: (value: ContactOrMailingListData) => void;
    required?: boolean;
    sampleURL?: string;
    textFieldTestId?: string;
    disabled?: boolean;
    csvHeaderOptions?: HeaderOptions;
}>) => {
    const { isModalOpen, closeModal, openModal } = useModal();

    const [uploadOpen, setUploadOpen] = useState(false);

    const handleUploadComplete = (data: CompletedUploadData) => {
        setValue(data);
    };

    const handleClear = () => {
        setValue(null);
    };

    const isMailingList = isMailingListValue(value);
    const isContact = isContactValue(value);

    return (
        <>
            <UploadContactsDialog
                open={uploadOpen}
                onClose={() => setUploadOpen(false)}
                onCompleted={handleUploadComplete}
                sampleURL={sampleURL}
                csvHeaderOptions={csvHeaderOptions}
            />
            <ConfirmDeleteDialog
                open={isModalOpen}
                onClose={closeModal}
                text="Are you sure you want to remove the uploaded recipients?"
                title="Remove Uploaded Recipients"
                confirm={handleClear}
            />
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={7}>
                    <ContactInput
                        label={label}
                        contact={isContact ? value : null}
                        setContact={setValue}
                        required={required && !isMailingList}
                        disabled={disabled || isMailingList}
                        textFieldTestId={textFieldTestId}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Typography align="center">or</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        fullWidth
                        disabled={disabled || isContact}
                        style={{ textTransform: 'none' }}
                        onClick={() => {
                            if (isMailingList) {
                                openModal();
                            } else {
                                setUploadOpen(true);
                            }
                        }}
                    >
                        {isMailingList
                            ? 'Remove Uploaded Recipients'
                            : 'Upload Recipients'}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default ContactOrCSV;
