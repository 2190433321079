import { useEffect, useState } from 'react';
import { Contact, useService } from '../services/Contacts';
import {
    ContactOrMailingListData,
    isContactValue,
} from '../components/ContactOrCSV';

export const useMailingListOrRegularContact = (
    contactOrMailingList: ContactOrMailingListData
) => {
    const contactService = useService();
    const [contact, setContact] = useState<Contact | null>(null);

    useEffect(() => {
        if (
            isContactValue(contactOrMailingList) ||
            contactOrMailingList === null
        ) {
            setContact(contactOrMailingList);
            return;
        }

        (async () => {
            try {
                const id = contactOrMailingList.mailingList.id;
                const res = await contactService.list({
                    skip: 0,
                    limit: 1,
                    search: `{"mailingLists": "${id}"}`,
                });

                setContact(res.data.length ? res.data[0] : null);
            } catch (e) {
                setContact(null);
            }
        })();
    }, [contactOrMailingList, contactService]);

    return contact;
};
