import React, { useState } from 'react';

import { useNotificationContext } from '../context/Notification';
import { downloadRemoteFile } from '../services/util';

import Spinner from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import GoBackButton from './GoBackButton';
import Button from './Button';

export type BulkCreationState =
    | { type: 'order_profile' }
    | { type: 'campaign' }
    | { type: 'campaign_report'; report: string; fileName: string }
    | { type: 'error'; message: string; severity?: 'error' | 'warning' }
    | null;

const BulkCreationDisplay = ({
    state,
}: {
    state: NonNullable<BulkCreationState>;
}) => {
    const [loading, setLoading] = useState(false);
    const { dispatchError } = useNotificationContext();

    const handleDownloadFile = async () => {
        if (state.type !== 'campaign_report') {
            return;
        }

        setLoading(true);
        try {
            await downloadRemoteFile(state.report, state.fileName);
        } catch (e) {
            console.error(e);
            dispatchError('Uh oh');
        } finally {
            setLoading(false);
        }
    };

    if (state.type === 'error') {
        return (
            <Grid item xs={12}>
                <Alert severity={state.severity ?? 'error'}>
                    <AlertTitle color={state.severity ?? 'error'}>
                        An Error Occured
                    </AlertTitle>
                    {state.message}
                </Alert>
            </Grid>
        );
    }

    if (state.type === 'campaign_report') {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2} direction="row">
                    <Grid
                        item
                        xs={2}
                        xl={1}
                        alignItems="center"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <CheckCircleIcon
                            color="primary"
                            sx={{ fontSize: 48 }}
                        />
                    </Grid>
                    <Grid item xs={10} xl={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    color="primary"
                                    sx={{ fontSize: 20, fontWeight: 600 }}
                                >
                                    Orders Created
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Your orders have successfully been created.
                                    Download the report to view details about
                                    the created orders.
                                </Typography>
                                <Typography>
                                    After you navigate away, the report will no
                                    longer be available.
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    onClick={handleDownloadFile}
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading ? (
                                        <Spinner color="primary" size="20px" />
                                    ) : (
                                        'Download Report'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography
                    color="primary"
                    sx={{
                        textAlign: 'center',
                        fontSize: 14,
                        fontWeight: 500,
                    }}
                >
                    Creating{' '}
                    {state.type === 'order_profile'
                        ? 'Order Profile'
                        : 'Campaign'}
                    ...
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <LinearProgress color="primary" />
            </Grid>
        </>
    );
};

const CreateOrderControls = ({
    disabled,
    bulkCreationState,
}: {
    /** Disables the create/back buttons */
    disabled?: boolean;
    bulkCreationState: BulkCreationState;
}) => {
    return (
        <Grid container spacing={1}>
            {bulkCreationState && (
                <Grid item xs={12}>
                    <Paper variant="outlined">
                        <Box margin={4}>
                            <Grid container spacing={2}>
                                <BulkCreationDisplay
                                    state={bulkCreationState}
                                />
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            )}
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={disabled}
                    fullWidth
                    size="large"
                >
                    Create
                </Button>
            </Grid>
            <Grid item xs={2}>
                <GoBackButton
                    color="primary"
                    variant="outlined"
                    disabled={disabled}
                    fullWidth
                    size="large"
                >
                    Back
                </GoBackButton>
            </Grid>
        </Grid>
    );
};

export default CreateOrderControls;
