import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
    createContext,
    useContext,
} from 'react';

type CbFn = () => void;

const isStateTheSame = (first: unknown, second: unknown) => {
    return JSON.stringify(first) === JSON.stringify(second); // TODO: Write a not awful comparison
    //TODO: Maybe we can use lodash isEqual here for deep equality checks
};

/**
 * This allows creating a context object which minimizes renders.
 * In order to access the store in this context, child components must call
 * `useStore` and specify a `selector` function which indicates and picks out
 * the properties of the context they care about. This selector prevents the
 * component from being re-rendered unless the props it cares about change.
 */
function createObjRefContext<Store>(initialStore: Store) {
    const useStateData = () => {
        const store = useRef(initialStore);
        const subscribers = useRef(new Set<CbFn>());

        const get = useCallback(() => store.current, []);
        const set = useCallback((value: Partial<Store>) => {
            store.current = { ...store.current, ...value };
            subscribers.current.forEach((cb) => cb());
        }, []);

        const subscribe = useCallback((cb: CbFn) => {
            subscribers.current.add(cb);
            return () => subscribers.current.delete(cb);
        }, []);

        return { get, set, subscribe };
    };

    const Context = createContext<ReturnType<typeof useStateData> | null>(null);

    function StoreProvider({ children }: { children: React.ReactNode }) {
        return (
            <Context.Provider value={useStateData()}>
                {children}
            </Context.Provider>
        );
    }

    function useStore<Selected>(selector: (store: Store) => Selected) {
        const store = useContext(Context);

        if (!store) {
            throw new Error(
                '`useStore` must be used within a `<StoreProvider />`.'
            );
        }

        const [state, setState] = useState(() => selector(store.get()));

        useEffect(() => {
            return store.subscribe(() => {
                const newState = selector(store.get());

                // Setting state for an object will always result in a state
                // update, even if the _look_ of the object is the same.
                // If the `Selector` is an object, it will _always_ re-render
                // since the previous and past state will be different.
                // We check in that case against the objects to ensure they are
                // structurally different.
                setState((prev) => {
                    if (
                        typeof newState !== 'object' ||
                        !isStateTheSame(newState, prev)
                    ) {
                        return newState;
                    }

                    return prev;
                });
            }) as () => void;
            // Do not need `selector` or `store` in the dependencies.
            // We only need this to execute once.
            // eslint-disable-next-line
        }, []);

        return [state, store.set] as const;
    }

    /**
     * @returns a function which, when invoked will return the current state of the store
     */
    function useGetStoreSnapshot() {
        const store = useContext(Context);
        if (!store) {
            throw new Error(
                '`useGetStoreSnapshot` must be used within a `<StoreProvider />`.'
            );
        }

        return useCallback(() => store.get(), [store]);
    }

    return { StoreProvider, useStore, useGetStoreSnapshot };
}

export default createObjRefContext;
